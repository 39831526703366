<div class="page-content">
  <h1>Mentions légales</h1>
  <p>STARATS</p>
  <p>Société par actions simplifiée au capital de 5000&nbsp;€</p>
  <p>RCS Nanterre 878 243 971</p>
  <p>Code APE 7022Z</p>
  <p>N° TVA&nbsp;: FR 77 878243971</p>
  <p>
    Siège social&nbsp;: 8, rue Claude Matrat - 92130 Issy-les-Moulineaux -
    France
  </p>
  <p>Téléphone&nbsp;: 06 07 50 91 29</p>
  <p>
    Adresse de courrier électronique&nbsp;:
    <app-email firstPart="jeanluc.meurisse" secondPart="starats.fr"></app-email>
  </p>
  <p>Directeur de la publication&nbsp;: Jean-Luc Meurisse, président</p>
  <p>
    Prestataire de formation&nbsp;: déclaration d'activité enregistrée sous le
    numéro 11922361192 auprès du préfet de région d'Île-de-France.
  </p>
  <br />

  Le site est hébergé par&nbsp;:
  <div class="legal__host_address">
    <p>
      OVH SAS
    </p>
    <p>
      Siège social&nbsp;: 2, rue Kellermann - 59100 Roubaix - France
    </p>
    <p>
      Téléphone&nbsp;: 09 72 10 10 07
    </p>
  </div>
  <br />

  <p>
    Le site n'utilise pas de traceurs ("cookies") et ne recueille pas de données
    personnelles.
  </p>

  <h1>Crédits photos</h1>
  <p>
    Page d'accueil&nbsp;: Guilhem Vellut (CC-BY 2.0),
    <a
      href="https://commons.wikimedia.org/wiki/Category:Pont_d%27Issy#/media/File:Seine_@_Pont_d'Issy-les-Moulineaux_@_Paris_(30782955666).jpg"
    >
      voir sur Wikimedia Commons</a
    >
  </p>

  <p>
    Page "À propos"&nbsp;: Guilhem Vellut (CC-BY 2.0),
    <a
      href="https://www.flickr.com/photos/o_0/30730948331/in/album-72157676127499886/"
    >
      voir sur Flickr</a
    >
  </p>
  <p>
    Page "Nos services"&nbsp;: Guilhem Vellut (CC-BY 2.0),
    <a
      href="https://www.flickr.com/photos/o_0/30519239530/in/album-72157676127499886/"
      >voir sur Flickr</a
    >
  </p>
</div>
